import * as React from "react"

const Benefit = ({title, desc}) => {
    return (
            <div className="w-full bg-dark-gray flex items-center pl-10 pr-5 py-6 shadow-infobox relative text-white max-w-benefits-feature">
                <div className="bg-red rounded-full h-6 w-6 absolute -left-2.5"></div>

                
                <div className="flex flex-col space-y-2">
                    <h6>{title}</h6>
                    <p className="text-feature-desc">{desc}</p>
                </div>
            </div>

    )
}

export default Benefit;