import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Logotypes from "../components/logotypes";
import ParagraphFullWidth from "../components/paragraph-full-width";
import ParagraphWithImage from "../components/paragraph-with-image";
import Benefits from "../components/benefits";

const PartnersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      partnerSub: markdownRemark(
        frontmatter: { name: { eq: "partner_content" } }
      ) {
        frontmatter {
          sub {
            title
            text
          }
        }
      }
      readyAudience: markdownRemark(
        frontmatter: { name: { eq: "partner_content" } }
      ) {
        frontmatter {
          ready_audience {
            title
            subtitle
            text
            tip
          }
        }
      }
      establishedDistribution: markdownRemark(
        frontmatter: { name: { eq: "partner_content" } }
      ) {
        frontmatter {
          established_distribution {
            title
            text
          }
        }
      }
      api: markdownRemark(frontmatter: { name: { eq: "partner_content" } }) {
        frontmatter {
          api {
            title
            first_paragraph
            second_paragraph
          }
        }
      }
    }
  `);

  const { title: subTitle, text: subText } = data?.partnerSub?.frontmatter.sub;
  const {
    title: readyTitle,
    subtitle,
    text: readyText,
    tip,
  } = data.readyAudience.frontmatter.ready_audience;
  const { title: establishedTitle, text: establishedText } =
    data.establishedDistribution.frontmatter.established_distribution;
  const {
    title: apiTitle,
    first_paragraph,
    second_paragraph,
  } = data.api.frontmatter.api;

  return (
    <>
      <SEO title="Partners" />
      <Layout>
        <main>
          <Hero />
          <Logotypes last={true} shadow={true} />
          <ParagraphFullWidth heading={subTitle} desc={subText} />

          <div className="flex flex-col space-y-10 md:space-y-20 mx-5 sm:mx-10 xl:mx-10">
            <ParagraphWithImage
              heading={readyTitle}
              subHeading={subtitle}
              desc={readyText}
              buttonText="Let’s Talk Terms!"
              buttonLink="/contact"
              secondary={false}
              italic={tip}
              section="partners_first"
            />

            <ParagraphWithImage
              heading={establishedTitle}
              desc={establishedText}
              alt="Establish Distribution Graphic"
              section="partners_second"
            />

            <ParagraphWithImage
              heading={apiTitle}
              desc={[
                `${first_paragraph}`,
                <br />,
                <br />,
                `${second_paragraph}`,
              ]}
              buttonText="Request More Info"
              buttonLink="/contact"
              secondary={false}
              section="partners_third"
            />
          </div>

          <Benefits />
        </main>
      </Layout>
    </>
  );
};

export default PartnersPage;
