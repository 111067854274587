import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import Benefit from "./benefit";
import Button from "./button";

const Benefits = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { name: { eq: "partner_content" } }) {
        frontmatter {
          benefits {
            title
            text
            benefits {
              benefit_1 {
                title
                text
              }
              benefit_2 {
                title
                text
              }
              benefit_3 {
                title
                text
              }
              benefit_4 {
                title
                text
              }
            }
          }
        }
      }
      benefitsBG: file(relativePath: { eq: "benefits-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { title, text, benefits } = data.markdownRemark.frontmatter.benefits;
  const { benefit_1, benefit_2, benefit_3, benefit_4 } = benefits;

  return (
    <BackgroundImage
      className="bg-black py-14 md:py-40 flex items-center justify-center mt-10 lg:mt-20"
      fluid={data.benefitsBG.childImageSharp.fluid}
    >
      <div className="flex items-center justify-between max-w-container w-full flex-col lg:flex-row mx-5 sm:mx-10 space-y-10 lg:space-y-0">
        <div className="max-w-container space-y-5 lg:space-y-10">
          <h1 className="max-w-benefits-heading">{title}</h1>
          <h3 className="max-w-benefits-sub-heading">{text}</h3>
          <Button
            buttonText="Request a Meeting"
            buttonLink="/contact"
            secondary={false}
          />
        </div>

        <div className="flex flex-col space-y-5">
          <Benefit title={benefit_1.title} desc={benefit_1.text} />
          <Benefit title={benefit_2.title} desc={benefit_2.text} />
          <Benefit title={benefit_3.title} desc={benefit_3.text} />
          <Benefit title={benefit_4.title} desc={benefit_4.text} />
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Benefits;
