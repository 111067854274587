import * as React from "react"

const ParagraphFullWidth = ({heading, desc}) => {
    return (
        <div className=" max-w-container flex flex-col space-y-10 items-center justify-center text-center py-20 px-10 lg:px-0 mx-auto">
            <h2 className="max-w-paragraph-heading">{heading}</h2>
            <p className="max-w-paragraph-desc">{desc}</p>
        </div>
    )
}

export default ParagraphFullWidth;