import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { name: { eq: "partner_content" } }) {
        frontmatter {
          hero {
            title
            text
          }
        }
      }
      mapBG: file(relativePath: { eq: "hero-bg-pattern.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { title, text } = data.markdownRemark.frontmatter.hero;

  return (
    <div className="bg-black">
      <BackgroundImage
        className="bg-black bg-cover"
        fluid={data.mapBG.childImageSharp.fluid}
      >
        <div className="container flex flex-col items-center justify-center text-center space-y-10 py-20 lg:py-36 max-w-xs sm:max-w-sm lg:max-w-none">
          <h1
            className="max-w-hero"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <h3 className="max-w-hero-sub">{text}</h3>
        </div>
      </BackgroundImage>
    </div>
  );
};

export default Hero;
